<template>
  <div data-app>
    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Edit Ditribusi
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">
        <perfect-scrollbar
        class="scroll"
        style="max-height: 98%; position: relative;"
        >
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">

            <validationProvider name="Int/Eks" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-1" 
                label="Int/Eks:" 
                label-for="input-1"
                :invalid-feedback="errors[0]"
                :state="valid">
                <b-form-select2
                id="input-1"
                v-model="form.kategori"
                :options="opsKategoriDistribusi"
                required
                variant="solid"
                :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Strain" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group25" 
                label="Strain:" 
                label-for="input-2"
                :invalid-feedback="errors[0]"
                :state="valid">
                <b-form-select2
                id="input-2"
                v-model="form.strain"
                :options="opsStrain"
                required
                variant="solid"
                :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <b-row class="w-100 mx-0" v-for="(rinc, index) in form.rincian" :key="index">
                <b-col cols="10" class="pt-5 px-2 pb-0">
                    <h6 class="text-muted">Rinc. {{index + 1}}</h6>
                </b-col>
                <b-col cols="2" class="text-right pt-5 px-2 pb-0">
                    <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                    <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                    </b-button>
                </b-col>       
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-1`" 
                    label="Sex:" 
                    :label-for="`input-${index}-1`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <b-form-select2
                        :id="`input-${index}-1`"
                        v-model="rinc.sex"
                        :options="opsSex"
                        required
                        variant="solid"
                        :state="(errors.length == 0 && rinc.sex != null) ? true : null"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-2`" 
                    label="Jumlah:" 
                    :label-for="`input-${index}-2`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <vue-number-input 
                        :attrs="{ id: `input-${index}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="rinc.jumlah"
                        :min="0"
                        :max="9999999999"
                        required
                        center
                        controls
                        ></vue-number-input>
                    </b-form-group>
                    </validationProvider>
                </b-col>
            </b-row>

            <b-row class="w-100 mx-0">
                <b-col cols="12" class="text-right">
                    <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                    <i class="flaticon2-plus-1 p-0"></i>
                    Add Saleable
                    </b-button>
                </b-col>
            </b-row>

          </b-form>
          </ValidationObserver>

          <div class="mt-3">
              <button 
                @click="onSubmit" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-eye"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="closeDialog()"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Close
              </button>
          </div>
        </perfect-scrollbar>  
        </div>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "unggas-nasional-data-distribusi-send-doc-edit",
  props: {
      sdata: {
          required: true
      }
  },
  data() {
    return {
      loading: false,
      form: {
        _id: this.sdata._id || null,
        kategori: null,
        strain: null,
        tipe: null,
        rincian: [{
          sex: null,
          jumlah: 0
        }]
      },
      show: true,
      opsKategoriDistribusi: [{
        value: null,
        text: 'Silahkan pilih Int/Eks'
      }],
      opsStrain: [{
        value: null,
        text: 'Silahkan pilih Strain'
      }],
      opsSex: [{
        value: null,
        text: 'Silahkan pilih Sex'
      }]
    }
  },
  computed: {
    cekSex () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
  },
  methods: {
    addRincian(){
        this.form.rincian.push({
            sex: null,
            jumlah: 0
        })
    },
    deleteRincian(index){
        if (this.form.rincian.length > 1) {
            this.form.rincian.splice(index,1);
        }
        else {
            this.form.rincian = [{
                    sex: null,
                    jumlah: 0
                }
            ]
        }
    },
    closeDialog() {
        return this.$emit('close-modal', {status: true, refresh: false})
    },
    getTernakSex(val) {
      if (!val) {
        return
      }
      MasterService.getTernakSex({tipe: val})
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsSex = [{
                  value: null,
                  text: "Silahkan pilih Sex"
              }]
              this.loading = false
              return;
          }

          this.opsSex = res.data.data
          this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
          })
          this.loading = false
      })
      .catch((err) => {
        this.opsSex = [{
            value: null,
            text: "Silahkan pilih Sex"
        }]
        this.loading = false
        ErrorService.status(err)
      })
    },
    getTernakStrain() {
      MasterService.getTernakStrain()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsStrain = [{
                  value: null,
                  text: "Tidak ada Strain"
              }]
              return;
          }

          this.opsStrain = res.data.data
          this.opsStrain.push({
              value: null,
              text: "Silahkan pilih Strain"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsStrain = [{
            value: null,
            text: "Tidak ada Strain"
        }]
      })
    },
    getKategoriDistribusi() {
      MasterService.getKategoriDistribusi()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriDistribusi = [{
                  value: null,
                  text: "Tidak ada Int/Eks"
                }]
                return;
            }

            this.opsKategoriDistribusi = res.data.data
            this.opsKategoriDistribusi.push({
              value: null,
              text: "Silahkan pilih Int/Eks"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKategoriDistribusi = [{
            value: null,
            text: "Tidak ada Int/Eks"
          }]
        })
    },
    getData () {
      DistribusiService.getSendDocEdit({distribusi: this.sdata._id})
      .then((res) => {
        if (res.data.status) {
          this.form = res.data.data 
          this.getTernakSex(this.form.tipe)
          return;
        }
        ErrorService.message(res.data)
        this.closeDialog()
      })
      .catch((err) => {
        ErrorService.status(err)
        this.closeDialog()
      })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data tidak lengkap',
            })
            return;
        }

        if (this.cekSex.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Double input sex',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            DistribusiService.updateSendDoc(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.onReset()
                      this.$emit('close-modal', {status: true, refresh: true})
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.form.kategori = null
      this.form.strain = null
      this.form.rincian = [{
        sex: null,
        jumlah: 0
      }]
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Distribusi DOC" }]);
    this.getTernakStrain()
    this.getKategoriDistribusi()
    this.getData();
  },
};
</script>
